import { ApplicationTools } from '@local/application-tool'
import { GraphqlPlayground } from '@local/graphql-playground'
import {
  ImportMapTool,
  useModulesWithFailedOverrideWarning
} from '@local/import-map-tool'
import { useImportMap } from '@local/import-map-tool/ImportMapTool/useImportMap'
import { useUrlParams } from '@local/import-map-tool/ImportMapTool/useUrlParams'
import { Preferences, usePreferences } from '@local/preferences-tool'
import { UserAuth } from '@local/user-authentication-tool'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ToastLogoIcon } from '@toasttab/buffet-pui-icons'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@toasttab/buffet-pui-tabs'
import cx from 'classnames'
import * as React from 'react'

export function App() {
  useUrlParams()
  const [areDevToolsOpen, setAreDevToolsOpen] = React.useState(false)
  const [
    { modalPin, modalSize, triggerPin, tabIndex, showDevTools },
    setPreferences
  ] = usePreferences()
  useModulesWithFailedOverrideWarning()

  const handleDevToolsToggle = () => {
    setAreDevToolsOpen((prev) => !prev)
  }

  const overridesCount =
    useImportMap().data?.reduce(
      (acc, d) => (d.state === 'overridden' ? acc + 1 : acc),
      0
    ) ?? 0

  if (!showDevTools) {
    console.log(
      'Toast dev tools disabled. Use `window.showDevTools()` in the console, and reload the page, to bring them back.'
    )
    return null
  }

  return (
    <div
      className={cx('fixed bottom-0 z-50 p-2', {
        'left-0': triggerPin === 'bottom-left',
        'right-0': triggerPin === 'bottom-right'
      })}
    >
      <IconButton
        cropToIcon
        icon={
          <div className='m-1'>
            <div
              className={
                overridesCount > 0 ? '-my-2 flex flex-row-reverse' : 'hidden'
              }
            >
              <div
                style={{
                  animationIterationCount: 5
                }}
                className='bg-error-100 absolute h-5 w-5 animate-ping rounded-full opacity-75'
              />
              <div className='bg-error-100 relative grid h-5 w-5 content-center justify-items-center rounded-full align-bottom'>
                <span className='type-overline font-normal text-white'>
                  {overridesCount}
                </span>
              </div>
            </div>
            <ToastLogoIcon
              size='md'
              className={cx(
                'bg-brand-50 hover:bg-brand-75 rounded-full p-1.5 text-white'
              )}
            />
          </div>
        }
        onClick={handleDevToolsToggle}
      />
      <Modal
        isOpen={areDevToolsOpen}
        onRequestClose={() => setAreDevToolsOpen(false)}
        parentSelector={() =>
          document.querySelector('[data-devt-portal-container]')!!
        }
        // Should be `none` but we need to figure out why the scroll behavior is weird
        overflowBehavior='body'
        size={modalSize}
        position={modalPin}
        shouldCloseOnEsc
      >
        <ModalHeader>Toast Dev Tools</ModalHeader>
        <ModalBody>
          <Tabs
            index={tabIndex || 0}
            onChange={(index) =>
              setPreferences((prev: any) => ({
                ...prev,
                tabIndex: index
              }))
            }
          >
            <TabList className='whitespace-nowrap'>
              <Tab>Import Map</Tab>
              <Tab>GraphQL</Tab>
              <Tab>User Authentication</Tab>
              <Tab>Application</Tab>
              <Tab>Preferences</Tab>
            </TabList>
            <TabPanels className='mt-4 md:mt-6'>
              <TabPanel>
                <ImportMapTool />
              </TabPanel>
              <TabPanel>
                <GraphqlPlayground />
              </TabPanel>
              <TabPanel>
                {/* <p>Not final, this is loading an external SPA!</p>
                <BanquetLoader name='demo' /> */}
                <UserAuth />
              </TabPanel>
              <TabPanel>
                <ApplicationTools />
              </TabPanel>
              <TabPanel>
                <Preferences />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </Modal>
    </div>
  )
}
