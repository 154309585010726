import * as React from 'react'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { AutoSuggestTextInput } from '@toasttab/buffet-pui-lookup'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { gql, useQuery } from '@apollo/client'
import { useCombobox } from 'downshift'

import {
  Environment,
  QuerySpaVersionsArgs,
  SpaVersion,
  SpaVersionsQuery
} from '@local/types'
import { ToastImport } from './importMapApi'
import { CopyLink } from './CopyLink'
import { useImportMap } from './useImportMap'

export const GET_SPA_VERSIONS = gql`
  query SpaVersions($spaId: ID!, $environment: Environment!) {
    spaVersions(spaId: $spaId, environment: $environment) {
      version
      assets
      commitAuthor
      commitDate
      commitMsg
    }
  }
`
export const GET_ADHOC_VERSIONS = gql`
  query AdhocVersions($spaId: ID!) {
    adhocVersions(spaId: $spaId) {
      version
      assets
      commitAuthor
      commitDate
      commitMsg
      branch
    }
  }
`

type VersionsResponse = {
  adhocVersions: SpaVersion[]
}
type ImportMapMutations = ReturnType<typeof useImportMap>['mutations']
interface OverrideFormProps extends ToastImport, ImportMapMutations {}

const LOCAL_DEV_FLAG = '__LOCAL_DEV_BRANCH__'

function getLatestAdhocVersions(allVersions: Array<SpaVersion> = []) {
  const filteredVersions: Array<SpaVersion> = []

  const branchMap = allVersions.reduce((accumulator, item) => {
    if (item.branch) {
      const branchName = item.branch
      const versions = accumulator.get(branchName) ?? []
      versions.push(item)
      accumulator.set(branchName, versions)
    }
    return accumulator
  }, new Map<string, SpaVersion[]>())

  for (const [, versions] of branchMap) {
    const latestVersion = versions.reduce((a, b) =>
      (a.commitDate ?? 0) > (b.commitDate ?? 0) ? a : b
    )
    filteredVersions.push({
      ...latestVersion,
      version: latestVersion.branch || latestVersion.version
    })
  }

  return filteredVersions
}

export const OverrideForm = ({
  name,
  state,
  defaultJs,
  overrideJs,
  toggleOpen,
  addOverride,
  removeOverride,
  enableOverride,
  disableOverride
}: OverrideFormProps) => {
  const { data } = useQuery<SpaVersionsQuery, QuerySpaVersionsArgs>(
    GET_SPA_VERSIONS,
    {
      variables: { spaId: name, environment: Environment.Preprod }
    }
  )

  const { data: adhocVersions } = useQuery<
    VersionsResponse,
    QuerySpaVersionsArgs
  >(GET_ADHOC_VERSIONS, {
    variables: { spaId: name }
  })

  const spaVersions: Array<SpaVersion> = [
    {
      spaId: '',
      version: 'Local dev (with default port)',
      assets: {
        path: 'https://dev.eng.toastteam.com:9990/bundle.js'
      },
      branch: LOCAL_DEV_FLAG
    },
    ...(data?.spaVersions.map((v) => {
      return { spaId: name, ...v }
    }) || []),
    // Add adhoc versions to the end :shrug:
    ...getLatestAdhocVersions(adhocVersions?.adhocVersions)
  ]

  const [filteredSpaVersions, setFilteredSpaVersions] =
    React.useState(spaVersions)

  const itemToString = (item: SpaVersion | null) => {
    if (item?.branch === LOCAL_DEV_FLAG) {
      return item.assets.path
    }
    return item?.assets[name] || ''
  }

  const comboboxInstance = useCombobox({
    items: filteredSpaVersions,
    itemToString,
    initialInputValue: overrideJs || '',
    onInputValueChange: ({ inputValue }) => {
      comboboxInstance.setInputValue(inputValue || '')
      setFilteredSpaVersions(
        spaVersions.filter(
          (item) =>
            !inputValue ||
            itemToString(item)
              .toLowerCase()
              .startsWith(inputValue.toLowerCase()) ||
            item.version.toLowerCase().startsWith(inputValue.toLowerCase())
        )
      )
    },
    onSelectedItemChange: () => {
      comboboxInstance.closeMenu()
    }
  })
  const { inputValue: value } = comboboxInstance

  const tadpoleSpa = spaVersions.find((spa) => itemToString(spa) === value)

  return (
    <div className='grid gap-2 md:gap-4'>
      <TextInput label='Default URL' value={defaultJs || 'None'} readOnly />

      <AutoSuggestTextInput<SpaVersion>
        label='Override URL'
        helperText={tadpoleSpa ? `Version: ${tadpoleSpa.version}` : ''}
        items={filteredSpaVersions}
        comboboxInstance={comboboxInstance}
        renderItem={(value: SpaVersion | null) => (
          <div key={value?.version}>
            <div>{value?.version || ''}</div>
            <div className='text-secondary type-subhead'>
              {itemToString(value)}
            </div>
          </div>
        )}
        onFocus={() => {
          if (!value) {
            setFilteredSpaVersions(spaVersions)
          }
          comboboxInstance.openMenu()
        }}
        prefix={
          tadpoleSpa ? <CopyLink name={name} spaVersion={tadpoleSpa} /> : null
        }
        prefixVariant='iconButton'
        suffix={
          value ? (
            <IconButton
              icon={<CloseIcon />}
              contained
              onClick={() => {
                comboboxInstance.setInputValue('')
              }}
            />
          ) : spaVersions.length ? (
            <img
              height={24}
              width={24}
              src='https://cdn.toasttab.com/static/f6f13400bf1aa60334bc67215844fede6bdc5e81/projects/tadpole/logo.png'
              alt='tadpole spa versions'
            />
          ) : undefined
        }
        suffixVariant={
          value ? 'iconButton' : spaVersions.length ? 'icon' : undefined
        }
      />

      <div className='flex flex-row justify-end gap-4 md:gap-2'>
        {state === 'disabled' && (
          <Button
            variant='secondary'
            className='mr-auto'
            onClick={() => {
              enableOverride(name)
            }}
          >
            Enable
          </Button>
        )}

        {state === 'overridden' && (
          <Button
            variant='secondary'
            className='mr-auto'
            onClick={() => {
              disableOverride(name)
            }}
          >
            Disable
          </Button>
        )}

        <Button
          variant='link'
          onClick={() => {
            toggleOpen(name)
          }}
        >
          Cancel
        </Button>

        {(state === 'default' || value) && (
          <Button
            variant='primary'
            onClick={() => {
              addOverride(name, value)
              if (state === 'disabled') {
                enableOverride(name)
              }
            }}
            disabled={!value}
          >
            Apply
          </Button>
        )}

        {state !== 'default' && !value && (
          <Button
            onClick={() => {
              removeOverride(name)
            }}
          >
            Reset
          </Button>
        )}
      </div>
    </div>
  )
}
