import * as React from 'react'
import { SpaVersion } from '@local/types'
import { OVERRIDE_SPA, OVERRIDE_URL } from './useUrlParams'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CopyIcon } from '@toasttab/buffet-pui-icons'

type CopyLinkProps = {
  name: string
  spaVersion: SpaVersion
}
export function CopyLink({ name, spaVersion }: CopyLinkProps) {
  // generate a sharable link to override SPA with current version and location
  function getOverrideLink() {
    const params = new URLSearchParams(window.location.search)
    params.append(OVERRIDE_SPA, name)
    params.append(
      OVERRIDE_URL,
      spaVersion.assets[name] || spaVersion.assets.path
    )
    const overrideUrl = `${window.location.origin}${window.location.pathname}?${params}`
    navigator.clipboard.writeText(overrideUrl)
  }

  return (
    <IconButton
      className='type-subhead text-link outline-none'
      icon={<CopyIcon />}
      onClick={getOverrideLink}
      size='sm'
      testId='copy-link'
      title='Copy sharable link'
    />
  )
}
