import * as React from 'react'

export const OVERRIDE_SPA = 'overrideSpa'
export const OVERRIDE_URL = 'overrideUrl'

export const useUrlParams = () => {
  // only run on load
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has(OVERRIDE_SPA) && params.has(OVERRIDE_URL)) {
      // 1. add the import map override
      window.importMapOverrides.addOverride(
        params.get(OVERRIDE_SPA)!,
        params.get(OVERRIDE_URL)!
      )
      // 2. remove the override url params
      params.delete(OVERRIDE_SPA)
      params.delete(OVERRIDE_URL)
      const baseUrl = `${window.location.origin}${window.location.pathname}`
      const intendedUrl = params.size > 0 ? `${baseUrl}?${params}` : baseUrl
      // 3. refresh the page
      window.location.href = intendedUrl
    }
  }, [])

  return null
}
